

.features{
    list-style: none;
    padding: 10px;;
}

.features li{
    margin: 6px 0px;
}

.features .featureNotIncluded:before {
    content: '✗';
    color: red;
    font-weight: bold;
    margin-right:10px;
}


.features .featureIncluded:before {
    content: '✓';
    color: green;
    font-weight: bold;
    margin-right:10px;
}